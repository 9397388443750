import "animate.css";
import axios from "axios";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import SubmitBtn from "./SubmitBtn";

const QuotesAPI = () => {
  const clients = {
    name: "",
    mail: "",
    contact: "",
    service: "",
    message: "",
  };

  const [client, setClient] = useState(clients);
  const navigate = useNavigate();

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await axios
      .post("https://myvocabs-chintu.vercel.app/createQuote", client)
      .then((response) => {
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      id="contact"
      className="container-fluid py-5 wow fadeInUp"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">
                Request A Quote
              </h5>
              <h1 className="mb-0">
                Need A Free Quote? Please Feel Free to Contact Us
              </h1>
            </div>
            <div className="row gx-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-4">
                  <i className="fa fa-reply text-primary me-3" />
                  Reply within 24 hours
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-4">
                  <i className="fa fa-phone-alt text-primary me-3" />
                  24 hrs telephone support
                </h5>
              </div>
            </div>
            <p className="mb-4">
              Ready to take your business to the next level with a stunning
              website or mobile app? We’re here to help! Whether you have a
              detailed project in mind or just a rough idea, we’d love to hear
              from you. Contact us today to get a free, no-obligation quote.
            </p>
            <p className="mb-4">
              Don’t wait to transform your online presence. Contact us now to
              get started on your project and receive your free quote. We’re
              excited to work with you and help your business succeed.
            </p>
          </div>

          <div className="col-lg-5">
            <div
              className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
              data-wow-delay="0.9s"
            >
              <form onSubmit={submitForm}>
                <div className="row g-3">
                  <div className="col-xl-12">
                    <input
                      type="text"
                      onChange={inputHandler}
                      id="name"
                      name="name"
                      className="form-control bg-light border-0"
                      placeholder="Your Name"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      onChange={inputHandler}
                      id="mail"
                      name="mail"
                      className="form-control bg-light border-0"
                      placeholder="Your Email"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="number"
                      onChange={inputHandler}
                      id="contact"
                      name="contact"
                      className="form-control bg-light border-0"
                      placeholder="Your contact number"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    {/* <select
                      className="form-select bg-light border-0"
                      style={{ height: 55 }}
                      onChange={inputHandler}
                      id="service"
                      name="service"
                    >
                      <option selected="">Select A Service</option>
                      <option value={Website}>Website</option>
                      <option value={Portfolio - Website}>
                        Portfolio Website
                      </option>
                      <option value={E - Commerce}>E-Commerce</option>
                      <option value={Business - Website}>
                        Business Website
                      </option>
                      <option value={Blog - Website}>Blog Website</option>
                    </select> */}
                  </div>
                  <div className="col-12">
                    <textarea
                      onChange={inputHandler}
                      id="message"
                      name="message"
                      className="form-control bg-light border-0"
                      rows={3}
                      placeholder="Message"
                      defaultValue={""}
                    />
                  </div>
                  {/* <div className="col-12">
                    <button className="btn btn-dark w-100 py-3" type="submit">
                      Request A Quote
                    </button>
                  </div> */}
                  <div>
                    <SubmitBtn />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesAPI;

// <section>
//   <Header header="Add Vastu Note" />
//   <BackIcon back="/Vastu" />

//   <Container fluid className="addNPage">
//     <Form onSubmit={submitForm} className="editorStyle">
//       <ReactQuill
//         value={note.heading}
//         onChange={handleHeadingChange}
//         placeholder="Heading"
//         style={{ height: "120px" }}
//       />

//       <MyJodit value={note.body} onChange={handleBodyChange} name="body" />

//       <SubmitBtn />
//     </Form>
//   </Container>
// </section>
