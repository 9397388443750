// import SendIcon from "@mui/icons-material/Send";
// import { Button } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function SubmitBtn() {
  const [btnclicked, setBtnclicked] = useState(false);

  const clickHandler = () => {
    setBtnclicked(true);
  };

  return (
    <Button
      type="submit"
      variant="light"
      // endIcon={<SendIcon />}
      onClick={clickHandler}
      color={btnclicked ? "primary" : "success"}
      className="myBtn"
    >
      Request A Quote
    </Button>
  );
}
