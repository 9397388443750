import React from "react";
import About from "../Components/About";
import Features from "../Components/Features";
import Footer from "../Components/Footer";
import MyNavbar from "../Components/MyNavbar";
// import Quotes from "./Components/Quotes";
import Pricing from "../Components/Pricing";
import QuotesAPI from "../Components/QuotesAPI";
import Services from "../Components/Services";

const Home = () => {
  return (
    <>
      <MyNavbar />
      <About />
      <Features />
      <Services />
      <Pricing />
      <QuotesAPI />
      <Footer />
    </>
  );
};

export default Home;
