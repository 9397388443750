import "animate.css";
import React from "react";
import Intro1 from "../img/carousel-1.jpg";
// import logo from "../img/logo.png";
import "./style.css";

const MyNavbar = () => {
  return (
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
        <a href="index.html" className="navbar-brand p-0">
          <h3
            className="m-0 animate__animated animate__shakeY"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="logoText1">VG Tech</span>
            {/* <img src={logo} alt="logo"  className="img-fluid" /> */}
            <span className="logoText2">You Grow We build </span>
          </h3>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a
              href="https://vikashgoswami.tech/"
              className="nav-item nav-link active"
            >
              Home
            </a>
            <a href="#about" className="nav-item nav-link">
              About
            </a>
            <a href="#service" className="nav-item nav-link">
              Services
            </a>
            <a href="#contact" className="nav-item nav-link">
              Contact
            </a>
          </div>
        </div>
      </nav>

      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="w-100" src={Intro1} alt="corousal" />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: 900 }}>
                <h1 className="display-6 text-white mb-md-4 animate__animated animate__zoomIn">
                  Elevate Your Online Presence with Professional Website
                </h1>
              </div>
            </div>
          </div>

          {/* Additional Carousel Items */}
          {/* <div className="carousel-item">
            <img className="w-100" src="img/carousel-2.jpg" alt="intro1" />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: 900 }}>
                <h1 className="display-3 text-white mb-md-4 animate__animated animate__zoomIn">
                  Creative &amp; Innovative Digital Solution
                </h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyNavbar;
