import "animate.css";
import React from "react";
import "./style.css";

const Services = () => {
  return (
    <div
      id="service"
      className="container-fluid py-5 wow animate__animated animate__fadeInUp animate__delay-2s"
    >
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto animate__animated animate__zoomIn animate__delay-2s"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
          <h1 className="mb-0">
            Custom IT Solutions for Your Successful Business
          </h1>
        </div>

        <div className="row g-5">
          <div className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-3s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <h4 className="mb-3">Website Creation</h4>
              <p className="m-0">
                We create custom websites tailored to your specific needs,
                ensuring a unique and engaging user experience.
              </p>
              <a
                className="btn btn-lg btn-primary rounded"
                href="https://vikashgoswami.tech/"
              >
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-6s"
            data-wow-delay="0.6s"
          >
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <h4 className="mb-3">Landing Pages</h4>
              <p className="m-0">
                High-converting landing pages designed to capture leads and
                drive sales.
              </p>
              <a
                className="btn btn-lg btn-primary rounded"
                href="https://vikashgoswami.tech/"
              >
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-9s"
            data-wow-delay="0.9s"
          >
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <h4 className="mb-3">Website Redesign</h4>
              <p className="m-0">
                Give your existing website a fresh, modern look with our
                redesign services.
              </p>
              <a className="btn btn-lg btn-primary rounded" href="#home">
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-3s"
            data-wow-delay="0.3s"
          >
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <h4 className="mb-3">E-commerce Solutions</h4>
              <p className="m-0">
                Build a powerful online store to boost your sales and grow your
                customer base.
              </p>
              <a
                className="btn btn-lg btn-primary rounded"
                href="https://vikashgoswami.tech/"
              >
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-6s"
            data-wow-delay="0.6s"
          >
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <h4 className="mb-3">Mobile App Development</h4>
              <p className="m-0">
                We develop apps for both iOS and Android platforms, ensuring a
                seamless user experience across devices.
              </p>
              <a
                className="btn btn-lg btn-primary rounded"
                href="https://vikashgoswami.tech/"
              >
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 animate__animated animate__zoomIn animate__delay-6s"
            data-wow-delay="0.6s"
          >
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i class="fa fa-search text-white"></i>
              </div>
              <h4 className="mb-3">SEO Optimization</h4>

              <a
                className="btn btn-lg btn-primary rounded"
                href="vikashgoswami.tech"
              >
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
