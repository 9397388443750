import "animate.css";
import "./style.css";

function Footer() {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  {/* <div class="d-flex mb-2">
            <i class="bi bi-geo-alt text-primary me-2"></i>
            <p class="mb-0">123 Street, New York, USA</p>
          </div> */}
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">info@vikashgoswami.tech</p>
                  </div>
                  {/* <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">+91 7247210069</p>
                  </div> */}
                  <div className="d-flex mt-4">
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://vikashgoswami.tech"
                    >
                      <i className="fab fa-twitter fw-normal" />
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://vikashgoswami.tech/"
                    >
                      <i className="fab fa-facebook-f fw-normal" />
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://vikashgoswami.tech/"
                    >
                      <i className="fab fa-linkedin-in fw-normal" />
                    </a>
                    <a
                      className="btn btn-primary btn-square"
                      href="https://vikashgoswami.tech/"
                    >
                      <i className="fab fa-instagram fw-normal" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  ©
                  <a className="text-white" href="https://vikashgoswami.tech">
                    vikashgoswami.tech
                  </a>
                  &nbsp; All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Back to Top */}
      <a
        href="https://vikashgoswami.tech/"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </a>
    </>
  );
}

export default Footer;
