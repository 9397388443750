import "animate.css";
import React from "react";
import featureImg from "../img/feature.jpg";
import "./style.css";

const Features = () => {
  return (
    <div
      className="container-fluid py-5 animate__animated animate__fadeInUp"
      data-wow-delay="0.5s"
    >
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto animate__animated animate__zoomIn"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
          <h1 className="mb-0">
            We Are Here to Grow Your Business Exponentially
          </h1>
        </div>

        <div className="row g-5">
          <div className="col-lg-4">
            <div className="row g-5">
              <div
                className="col-12 animate__animated animate__zoomIn"
                data-wow-delay="0.2s"
              >
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fa fa-cubes text-white" />
                </div>
                <h4>Comprehensive Services</h4>
                <p className="mb-0">
                  We offer a wide range of web development services, from
                  initial design to ongoing maintenance and updates.
                </p>
              </div>

              <div
                className="col-12 animate__animated animate__wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fa fa-award text-white" />
                </div>
                <h4>Innovative Solutions</h4>
                <p className="mb-0">
                  We leverage the latest technologies and industry best
                  practices to create innovative solutions that help your
                  business stand out.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 animate__animated animate__wow zoomIn"
            data-wow-delay="0.9s"
            style={{ minHeight: 350 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded animate__animated animate__wow zoomIn"
                data-wow-delay="0.1s"
                src={featureImg}
                style={{ objectFit: "cover" }}
                alt="features"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row g-5">
              <div
                className="col-12 animate__animated animate__wow zoomIn"
                data-wow-delay="0.4s"
              >
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fa fa-users-cog text-white" />
                </div>
                <h4>Proven Track Record</h4>
                <p className="mb-0">
                  With a portfolio of successful projects and satisfied clients,
                  we have a proven track record of delivering exceptional
                  results.
                </p>
              </div>
              <div
                className="col-12 animate__animated animate__wow zoomIn"
                data-wow-delay="0.8s"
              >
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fa fa-phone-alt text-white" />
                </div>
                <h4>Post-Launch Support</h4>
                <p className="mb-0">
                  Our commitment to your success doesn’t end with the launch of
                  your website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
