import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Check2 } from "react-bootstrap-icons";

export default function Pricing() {
  return (
    <Container className="py-5">
      <h1 className="text-center mb-5 display-4 fw-bold">
        Web Service Pricing Plans
      </h1>
      <Row className="g-4">
        <Col xs={12} md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body className="d-flex flex-column">
              <div className="text-center mb-4">
                <div className="bg-light p-3 d-inline-block rounded-circle">
                  <svg width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"
                    />
                  </svg>
                </div>
                <h2 className="mt-3 text-primary">Basic Plan</h2>
                <p className="text-muted">Starter Website</p>
              </div>
              <div className="mb-4">
                <h3 className="mb-3">Services Included</h3>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>3-Page Website (Home, About, Contact)</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Responsive Design</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Basic SEO Setup</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Contact Form Integration</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>1 Round of Revisions</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>7 Days Turnaround Time</span>
                </div>
              </div>
              <div className="mt-auto text-center">
                <h3 className="display-6 mb-3">₹4999</h3>
                <p className="text-muted mb-4">(one-time fee)</p>
                <Button variant="outline-primary" size="lg" className="w-100">
                  Choose Plan
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 shadow bg-primary text-white">
            <Card.Body className="d-flex flex-column">
              <div className="text-center mb-4">
                <div className="bg-white p-3 d-inline-block rounded-circle">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    className="text-primary"
                  >
                    <path
                      fill="currentColor"
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z"
                    />
                  </svg>
                </div>
                <h2 className="mt-3">Standard Plan</h2>
                <p className="text-white-50">Business Website</p>
              </div>
              <div className="mb-4">
                <h3 className="mb-3">Services Included</h3>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>
                    5-Page Website (Home, About, Services, Portfolio/Gallery,
                    Contact)
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>Responsive Design</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>On-Page SEO Optimization</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>Contact Form & Newsletter Integration</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>Social Media Links Integration</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="me-2" />
                  <span>14 Days Turnaround Time</span>
                </div>
              </div>
              <div className="mt-auto text-center">
                <h3 className="display-6 mb-3">₹9999</h3>
                <Button variant="light" size="lg" className="w-100">
                  Choose Plan
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body className="d-flex flex-column">
              <div className="text-center mb-4">
                <div className="bg-light p-3 d-inline-block rounded-circle">
                  <svg width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </div>
                <h2 className="mt-3 text-primary">Premium Plan</h2>
                <p className="text-muted">E-commerce Website</p>
              </div>
              <div className="mb-4">
                <h3 className="mb-3">Services Included</h3>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Up to 15 Product Pages</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Responsive Design</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Complete SEO Setup</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Shopping Cart & Payment Gateway Integration</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>Product Showcase & Categories</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Check2 className="text-primary me-2" />
                  <span>21 Days Turnaround Time</span>
                </div>
              </div>
              <div className="mt-auto text-center">
                <h3 className="display-6 mb-3">₹24999</h3>
                <Button variant="outline-primary" size="lg" className="w-100">
                  Choose Plan
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
