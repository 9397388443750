import "animate.css";
import React from "react";
import aboutImg from "../img/about.jpg";
import "./style.css";

const About = () => {
  return (
    <div
      id="about"
      className="container-fluid py-5 animate__animated animate__fadeInUp animate__delay-2s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
              <h1 className="mb-0">The Best IT Solution</h1>
            </div>
            <p className="mb-4">
              We are dedicated to providing top-notch web development services
              to help businesses thrive online. With a passion for innovation,
              we deliver solutions that meet your unique needs and exceed your
              expectations.
            </p>

            <p className="mb-4">
              Choosing us means partnering with a dedicated team that is
              committed to your success. We go above and beyond to deliver web
              development solutions that not only meet but exceed your
              expectations. Let us help you create a powerful online presence
              that drives growth and sets your business apart from the
              competition. Contact us today to get started!
            </p>

            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow animate__animated animate__fadeInLeft animate__delay-3s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Attractive Layout
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Professional Staff
                </h5>
              </div>

              <div className="col-sm-6 wow animate__animated animate__fadeInRight animate__delay-4s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  24/7 Support
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Fair Prices
                </h5>
              </div>
            </div>

            <a
              href="quote.html"
              className="btn btn-primary py-3 px-5 mt-3 wow animate__animated animate__flip animate__delay-2s"
            >
              Request A Quote
            </a>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded animate__animated animate__zoomIn animate__delay-2s"
                src={aboutImg}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
